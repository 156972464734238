import React, { useEffect, useState } from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';

interface ConfirmationModalProps {
  children?: any;
  confirmationQuestion?: string;
  onConfirm: () => void;
  onReject: () => void;
  showModal?: boolean;
  variant?: boolean;
  buttonVariant?: `primary` | `danger`;
  buttonText?: string;
  isSubmit?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  buttonText = `Confirm`,
  buttonVariant = `primary`,
  children,
  confirmationQuestion,
  isSubmit = false,
  onConfirm,
  onReject,
  showModal = false,
  variant = true,
}) => {
  const [ isModalShown, setIsModalShown ] = useState(showModal);

  useEffect(() => {
    setIsModalShown(showModal);
  }, [ showModal ]);

  return (
    <Modal
      data-testid="modal"
      aria-labelledby="msg"
      show={isModalShown}
      className={ variant ? `modal-variant` : ``}
      onHide={
        () => {
          onReject();
          setIsModalShown(false);
        }
      }>
      <Modal.Header>
        <Modal.Title id="msg" data-testid="confirmTitle">{confirmationQuestion}</Modal.Title>
        <CloseButton data-testid="noBtn" onClick={onReject} />
      </Modal.Header>
      { children ? <Modal.Body>{children}</Modal.Body> : null }
      <Modal.Footer>
        <Button
          variant={buttonVariant}
          {...onConfirm ? { onClick: onConfirm } : {}}
          data-testid="yesBtn"
          {...isSubmit ? { type: `submit` } : {}}
        >{buttonText}</Button>
      </Modal.Footer>
    </Modal>
  );
};
