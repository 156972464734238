import {
  ManualDTO,
} from 'recertify';
import { saveAs } from 'file-saver';
import Axios from '../utils/http.config';

interface IEditManualForm {
  name: string;
  file: FileList;
  examType: number;
  document: number;
}

interface IDocumentDownloadData {
  exists: boolean;
  filename: string;
  path: string;
}

export class DocumentService {
  public static getManuals(): Promise<ManualDTO[]> {
    return Axios.get<{manuals: ManualDTO[]}>(`manual`)
      .then(response => response.data.manuals);
  }

  public static downloadManual(manualId: number): Promise<void> {
    return Axios.get<Blob>(`download/manual/${manualId}`, {
      responseType: `blob`,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]));
        saveAs(url, `manual_${manualId}.pdf`);
        window.URL.revokeObjectURL(url);
      });
  }

  public static downloadDocument(examId: number): Promise<void> {
    return Axios.get<Blob>(`/download/document/${examId}`, {
      responseType: `blob`,
    })
      .then((response) => {
        if (response.status === 404) {
          throw new Error(`Error downloading document: Document not found/uploaded`);
        }
        if (response.status !== 200) {
          throw new Error(`An unknown error while downloading the document has occured`);
        }
        const url = window.URL.createObjectURL(new Blob([ response.data ]));
        saveAs(url, `document_${examId}.pdf`);
        window.URL.revokeObjectURL(url);
      });
  }

  public static getDownloadDocumentInfo(examId: number): Promise<IDocumentDownloadData> {
    return Axios.get<{data: {downloadInfo: IDocumentDownloadData }}>(`/download/document/${examId}/info`)
      .then(response => response.data.data.downloadInfo);
  }

  public static getManualInfoForId(id: number): Promise<ManualDTO> {
    return Axios.get<{data: ManualDTO}>(`/admin/manual/${id}/edit`)
      .then(response => response.data.data);
  }

  public static updateManual(id: number, data: IEditManualForm): Promise<void> {

    const formattedOptions = {
      "file": data?.file[0],
      'options[document]': data?.document,
      'options[examType]': data?.examType,
      'options[name]': data?.name,
    };

    return Axios.post(`/admin/manual/${id}/edit`,
      formattedOptions,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
  }

  public static deleteManual(id: number): Promise<void> {
    return Axios.post(`/admin/manual/${id}/delete`);
  }
}
