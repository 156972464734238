import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  PaginationState,
  RowSelectionState,
  SortingState,
  Row as TableRow,
  useReactTable,
} from '@tanstack/react-table';
import cn from 'classnames';
import { Button, Col, FormCheck, Row } from 'react-bootstrap';
import { Table as BTable } from 'react-bootstrap';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import 'scss/table.scss';
import { useErrorHandler } from 'react-error-boundary';
import { useAsync } from '@react-hook/async';
import { IPaginatedResponse } from 'recertify';
import { useDebounce } from '@react-hook/debounce';
import ReactPaginate from 'react-paginate';
import { isEqual } from 'lodash';
import { getClickableProps } from 'components/DivButton';
import { useBreakpoint } from '../../hooks';
import { FullPageSpinner } from '../Spinner';
import { ColumnFilter } from './filters';

interface TableProps<T extends Record<string, any>> {
  columns: Array<ColumnDef<T, any>>;
  data?: T[];
  getRowProps?: (row: TableRow<T>) => { [key: string]: any };
  responsive?: boolean | `sm` | `md` | `lg` | `xl`;
  colWidths?: number[];

  // Searching
  noItemsText?: React.ReactNode;

  // Selecting
  selectable?: boolean;
  onSelectionChange?: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  renderSubComponent?: (props: { row: TableRow<T> }) => ReactNode;
  clickRowToExpand?: boolean;

  // Pagination
  paginate?: boolean | `manual`;
  defaultPageSize?: number;
  startingPage?: number;
  dataProps?: { [key: string]: any };
  fetchData?: (props: any) => Promise<IPaginatedResponse<T> | T[]>;

  // Sorting
  sortable?: boolean | `manual`;
  sortBy?: SortingState;
  defaultGroupBy?: GroupingState;
  tableFilter?: Array<{ id: string, value: string }>;
}
const defaultPropGetter = () => ({});

export const Table: <T extends Record<string, any>>(props: TableProps<T>) =>
React.ReactElement<TableProps<T>> = ({
  clickRowToExpand = false,
  colWidths = [],
  columns,
  data = [],
  dataProps,
  defaultGroupBy = [],
  defaultPageSize = 15,
  fetchData,
  getRowProps = defaultPropGetter,
  noItemsText,
  onSelectionChange,
  paginate = false,
  renderSubComponent,
  responsive = false,
  selectable = false,
  sortBy = [],
  sortable = false,
  startingPage = 0,
  tableFilter,
}) => {
  const handleError = useErrorHandler();
  const breakpoint = useBreakpoint();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ searchParams, setSearchParams ] = useDebounce<typeof dataProps>({}, 500);
  const [ tableData, setTableData ] = useState<typeof data>(data);
  const [ columnFilters, setColumnFilters ] = useState<ColumnFiltersState>([]);
  const [ sorting, setSorting ] = useState<SortingState>(sortBy);
  const [ pagination, setPagination ] = useState<PaginationState>({
    pageIndex: startingPage,
    pageSize: defaultPageSize,
  });
  const [ grouping, setGrouping ] = useState<GroupingState>(defaultGroupBy);
  const [ expanded, setExpanded ] = useState<ExpandedState>({});
  const [ rowSelection, setRowSelection ] = useState<RowSelectionState>({});
  const formatSortBy = useCallback((sort: typeof sortBy) => sort
    .map(({ desc, id }) => ({ [id]: desc ? `desc` : `asc` }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  []);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(rowSelection);
    }
  }, [ onSelectionChange, rowSelection ]);

  useEffect(() => {
    if (!fetchData) {
      setTableData(data);
    }
  }, [ data, fetchData ]);

  const [{ error, status, value: results }, callFetchData ] = useAsync(() => fetchData ? fetchData({
    ...dataProps,
    ...searchParams,
    ...sortable === `manual` && { sort: formatSortBy(sorting) },
    ...paginate === `manual` && { length: pagination.pageSize, page: pagination.pageIndex },
  }) : Promise.resolve({ data: [], totalPages: 0 }));

  useEffect(() => {
    if (fetchData) {
      if (pagination.pageIndex !== startingPage) {
        setPagination((prev) => ({ ...prev, pageIndex: startingPage }));
      } else {
        void callFetchData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ fetchData, dataProps, searchParams ]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: startingPage }));
    setColumnFilters([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dataProps ]);

  useEffect(() => {
    if (fetchData && paginate === `manual` && ![ `loading`, `idle` ].includes(status)) {
      void callFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ fetchData, pagination.pageSize, pagination.pageIndex ]);

  useEffect(() => {
    if (fetchData && sortable === `manual` && ![ `loading`, `idle` ].includes(status)) {
      void callFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ fetchData, sorting ]);

  useEffect(() => {
    if (!fetchData) {
      return;
    }

    switch (status) {
      case `loading`:
      case `idle`:
        setIsLoading(true);
        break;
      case `error`:
        handleError(error);
        break;
      case `success`:
        setIsLoading(false);
        setTableData(Array.isArray(results) ? results : results?.data || []);
        break;
      default:
        throw new Error(`Unhandled status: ${status}`);
    }
  }, [ error, fetchData, handleError, results, status ]);

  useEffect(() => {
    handleError(error);
  }, [ error, handleError ]);

  const table = useReactTable({
    autoResetExpanded: false,
    columns: [
      ...selectable ? [{
        cell: ({ row }) =>
          <div className="px-1">
            <FormCheck
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>,
        enableSorting: false,
        header: ({ table: t }) =>
          <FormCheck
            {...{
              checked: t.getIsAllRowsSelected(),
              indeterminate: t.getIsSomeRowsSelected(),
              onChange: t.getToggleAllRowsSelectedHandler(),
            }}
          />,
        id: `selection`,
      }] as typeof columns : [],
      ...columns,
    ],
    data: tableData,
    enableRowSelection: selectable,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowCanExpand: () => renderSubComponent !== undefined,
    getSortedRowModel: getSortedRowModel(),
    manualFiltering: !!(fetchData && paginate === `manual`),
    manualPagination: !!(fetchData && paginate === `manual`),
    manualSorting: !!(fetchData && sortable === `manual`),
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onGroupingChange: setGrouping,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    pageCount: fetchData ? !Array.isArray(results) ? results?.totalPages : undefined : undefined,
    state: {
      columnFilters: tableFilter?.length ? tableFilter : columnFilters,
      expanded,
      grouping,
      pagination,
      rowSelection,
      sorting,
    },
  });

  useEffect(() => {
    const newSearchParams = columnFilters
      .map(({ id, value }) => ({ [id]: value }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    if (!isEqual(newSearchParams, searchParams)) {
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ columnFilters ]);

  return <Row>
    <Col>
      {!!defaultGroupBy.length && <Row>
        <Col>
          <Button onClick={() => { table.toggleAllRowsExpanded(); }}>
            {table.getIsAllRowsExpanded() ? `Collapse` : `Expand`} All
          </Button>
        </Col>
      </Row>}
      <Row>
        <Col className={cn({
          'table-responsive': responsive && typeof responsive === `boolean`,
          'table-responsive-lg': responsive === `lg`,
          'table-responsive-md': responsive === `md`,
          'table-responsive-sm': responsive === `sm`,
          'table-responsive-xl': responsive === `xl`,
        })}>
          <BTable striped responsive>
            <thead>
              {table.getHeaderGroups().map(headerGroup =>
                <React.Fragment key={headerGroup.id}>
                  <tr>
                    {headerGroup.headers.map(header =>
                      <th key={header.id} colSpan={header.colSpan} style={{
                        width: colWidths[header.index] ? colWidths[header.index] : `auto`,
                      }}>
                        {header.isPlaceholder ?
                          null :
                          <div
                            className={cn({
                              'cursor-pointer': header.column.getCanSort(),
                              'select-none': header.column.getCanSort(),
                            })}
                            onClick={header.column.getToggleSortingHandler()}
                            onKeyDown={(e) => {
                              if (e.key !== `Tab`) {
                                header.column.toggleSorting();
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {header.column.getCanSort() ? <span className="sort-arrows text-nowrap">{{
                              asc: <>
                                <FaLongArrowAltUp className="ml-2 text-dark" />
                                <FaLongArrowAltDown style={{ marginLeft: `-0.5rem` }}
                                />
                              </>,
                              desc: <>
                                <FaLongArrowAltUp className="ml-2" />
                                <FaLongArrowAltDown className="text-dark" style={{ marginLeft: `-0.5rem` }} />
                              </>,
                            }[header.column.getIsSorted() as string] ?? <>
                              <FaLongArrowAltUp className="ml-2" />
                              <FaLongArrowAltDown style={{ marginLeft: `-0.5rem` }} />
                            </>}
                            </span> : null}
                          </div>}
                      </th>)}
                  </tr>
                  {headerGroup.headers.some(header => header.column.getCanFilter()) &&
                    <tr key="filters">
                      {headerGroup.headers.map(header => <td key={`filter-${header.id}`}>
                        {header.column.getCanFilter() ?
                          <ColumnFilter table={table} column={header.column} /> :
                          null}
                      </td>)}
                    </tr>}
                </React.Fragment>)}
            </thead>
            <tbody data-testid="tableBody">
              {isLoading ? <tr><td colSpan={table.getVisibleFlatColumns().length}><FullPageSpinner /></td></tr> :
                table.getRowModel().rows.length || !noItemsText ?
                  table.getRowModel().rows.map(row => <React.Fragment key={row.id}>
                    <tr
                      {...getRowProps(row)}
                      {...(row.original as { id?: string })?.id ? {
                        'data-testid': `tableRow-${(row.original as unknown as { id: string }).id}`,
                      } : {}}
                      {...clickRowToExpand ?
                        {
                          ...getClickableProps(row.getToggleExpandedHandler()),
                          "aria-label": `Expand Row`,
                        } :
                        {}
                      }
                    >
                      {row.getVisibleCells().map(cell => <td
                        key={cell.id}
                        {...cell.column.columnDef.testId ? { 'data-testid': cell.column.columnDef.testId } : {}}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>)}
                    </tr>
                    {
                      row.getIsExpanded() && renderSubComponent !== undefined ? <tr>
                        <td style={{ padding: `0px` }} colSpan={row.getVisibleCells().length}>
                          {renderSubComponent({ row })}
                        </td>
                      </tr> : undefined
                    }
                  </React.Fragment>) :
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="text-center my-3">
                        <p>
                          {noItemsText}
                        </p>
                      </div>
                    </td>
                  </tr>}
            </tbody>
          </BTable>
          {paginate && !isLoading &&
            <Row>
              {table.getPageCount() > 0 && <Col sm="9">
                <ReactPaginate
                  containerClassName="pagination"
                  activeClassName="active"
                  disabledClassName="disabled"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  breakLabel="..."
                  previousLabel={<RiArrowLeftSLine data-testid="previousPage" />}
                  nextLabel={<RiArrowRightSLine data-testid="nextPage" />}
                  onPageChange={({ selected }) => {
                    table.setPageIndex(selected);
                  }}
                  forcePage={pagination.pageIndex}
                  pageRangeDisplayed={
                    breakpoint === `lg` ? 8 :
                      breakpoint === `md` ? 5 :
                        breakpoint === `sm` ? 3 : 2
                  }
                  marginPagesDisplayed={
                    breakpoint === `lg` || breakpoint === `md` ? 3 :
                      breakpoint === `sm` ? 2 : 1
                  }
                  pageCount={table.getPageCount()}
                />
              </Col>}
            </Row>}
        </Col>
      </Row>
    </Col>
  </Row>;
};
